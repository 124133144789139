
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import axios from 'axios';
import DateRangePicker from '@gid/vue-common/components/DateRangePicker.vue';
import { AccountingDocument, PaymentRecord } from '@gid/models';
import PaymentAmount from './PaymentAmount.vue';
import PaymentSelector from './PaymentSelector.vue';
import DocumentSelector from './DocumentSelector.vue';
import { AccountingDocumentTypeEnum } from '@gid/models';
import PaymentLabel from './PaymentLabel.vue';
import DocumentLabel from './DocumentLabel.vue';

import { BModal } from 'bootstrap-vue';
import { plainToClass } from 'class-transformer';
import {
  CreateQueryParams,
  SConditionAND,
  SFields,
} from '@dataui/crud-request';

interface SelectOption {
  label: string;
  value: string;
}

function cmpDate(a, b) {
  return new Date(a.issueDate).valueOf() - new Date(b.issueDate).valueOf();
}

@Component({
  components: {
    PaymentAmount,
    PaymentSelector,
    DocumentSelector,
    PaymentLabel,
    DocumentLabel,
    DateRangePicker,
  },
})
export default class ReconcilePayment extends Vue {
  @Ref('modal') readonly modal!: BModal;

  inProgress: boolean = false;
  paymentId: string | null = null;
  initialDocuments: AccountingDocument[] = [];
  initialPayments: PaymentRecord[] = [];
  documents: AccountingDocument[] = [];
  payments: PaymentRecord[] = [];
  updates: { [key: string]: Partial<AccountingDocument> } = {};
  error: any = null;
  filters: {
    payment: 'prepayment' | 'postpayment' | 'range' | null;
    documentsDateRange: {
      start: Date | null;
      end: Date | null;
    };
  } = {
    payment: null,
    documentsDateRange: {
      start: null,
      end: null,
    },
  };

  get documentsTotal(): number {
    const totalCents = this.documents.reduce(
      (sum, doc) =>
        sum +
        Math.round(
          100 *
            (doc.type === AccountingDocumentTypeEnum.CREDIT_NOTE
              ? -(doc.totalAmount + doc.vatAmount)
              : doc.totalAmount + doc.vatAmount),
        ),
      0,
    );
    return totalCents / 100;
  }
  get paymentsTotal(): number {
    const totalCents = this.payments.reduce(
      (sum, payment) => sum + Math.round(100 * payment.amount),
      0,
    );
    return totalCents / 100;
  }

  get documentsSorted() {
    return this.documents.sort(cmpDate);
  }

  get paymentsSorted() {
    return this.payments.sort(cmpDate);
  }

  get hasUpdates() {
    return Object.keys(this.updates).length > 0;
  }

  get initialJobIds() {
    return new Set(
      this.initialDocuments.flatMap((doc) => doc.jobs.map((j) => j.id)),
    );
  }

  get jobs() {
    return this.documents
      .flatMap((doc) =>
        doc.jobs.map((j) => ({
          ...j,
          isNew: !this.initialJobIds.has(j.id),
        })),
      )
      .sort((a, b) => (a.isNew as any) - (b.isNew as any));
  }

  get documentsQueryParams() {
    if (this.filters.payment === 'range') {
      const search: Array<SFields | SConditionAND> = [];
      if (this.filters.documentsDateRange.start) {
        search.push({
          issueDate: {
            $gte: this.filters.documentsDateRange.start.toISOString(),
          },
        });
      }
      if (this.filters.documentsDateRange.end) {
        search.push({
          issueDate: {
            $lte: this.filters.documentsDateRange.end.toISOString(),
          },
        });
      }
      return { search };
    }

    const op =
      this.filters.payment === 'prepayment'
        ? '$lte'
        : this.filters.payment === 'postpayment'
        ? '$gte'
        : null;
    if (op && this.paymentsSorted.length > 0) {
      const earliestPaymentDate = this.paymentsSorted
        .map((p) => p.date)
        .sort(cmpDate)[0];
      return {
        search: {
          issueDate: {
            [op]: earliestPaymentDate.toISOString(),
          },
        },
      } as CreateQueryParams;
    }
    return {};
  }

  openForPayment(paymentId) {
    this.paymentId = paymentId;
    this.loadPreview();
    this.modal.show();
  }

  modalClosed() {
    this.$emit('close');
  }

  loadPreview() {
    this.inProgress = true;
    this.error = null;
    axios
      .get(`/documents-api/accounting/payments/reconcile/${this.paymentId}`)
      .then((result) => {
        this.initialPayments = plainToClass(
          PaymentRecord,
          result.data.paymentRecords as any[],
        );
        this.initialDocuments = plainToClass(
          AccountingDocument,
          result.data.documents as any[],
        );
        this.payments = this.initialPayments.slice();
        this.documents = this.initialDocuments.slice();
        this.updates = result.data.updates
          ? result.data.updates.reduce(
              (u, doc) => ({ ...u, [doc.id]: doc }),
              {},
            )
          : {};
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  updatePreview() {
    this.inProgress = true;
    this.error = null;
    axios
      .post(`/documents-api/accounting/payments/reconcile/preview`, {
        paymentRecords: this.payments,
        documents: this.documents,
      })
      .then((result) => {
        this.updates = result.data.updates
          ? result.data.updates.reduce(
              (u, doc) => ({ ...u, [doc.id]: doc }),
              {},
            )
          : {};
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  save() {
    this.inProgress = true;
    this.error = null;
    axios
      .post(`/documents-api/accounting/payments/reconcile/${this.paymentId}`, {
        paymentRecords: this.payments,
        documents: this.documents,
      })
      .then(() => {
        this.modal.hide();
      })
      .catch((error) => {
        this.error = error;
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  addPayment(payment) {
    this.payments.push(payment);
    this.updatePreview();
  }

  removePayment(payment) {
    this.payments = this.payments.filter((p) => p.id !== payment.id);
    this.updatePreview();
  }

  addDocument(document) {
    this.documents.push(document);
    this.updatePreview();
  }

  removeDocument(document) {
    this.documents = this.documents.filter((p) => p.id !== document.id);
    this.updatePreview();
  }
}
