
import axios, { CancelTokenSource } from 'axios';
import { debounce } from 'lodash';
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import {
  CreateQueryParams,
  SConditionAND,
  SFields,
  RequestQueryBuilder,
} from '@dataui/crud-request';

import PaymentAmount from './PaymentAmount.vue';
import PaymentLabel from './PaymentLabel.vue';

@Component({
  components: {
    PaymentAmount,
    PaymentLabel,
  },
})
export default class PaymentSelector extends Vue {
  @Prop({ required: true }) endpoint!: string;
  @Prop({ required: true }) searchFields!: string[];
  @Prop({ required: true }) trackBy!: string;
  @Prop({ required: true }) label!: string;
  @Prop({ default: () => [] }) additionalFilters!: Array<
    SFields | SConditionAND
  >;
  @Prop() placeholder!: string;
  @Prop() queryParams!: CreateQueryParams;

  isLoading: boolean = false;
  options = [];
  cancelSource: CancelTokenSource | null = null;
  selected: any = null;

  get value() {
    // return this.selected;
    return undefined;
  }

  set value(data) {
    this.selected = data;
    this.$emit('input', this.selected);
  }

  buildSearch(query) {
    const search: Array<SFields | SConditionAND> = [];
    if (query && this.searchFields?.length) {
      search.push({
        $or: this.searchFields.map((field) => ({
          [field]: {
            $contL: query,
          },
        })),
      });
    }

    return {
      $and: search,
    };
  }

  async find(query) {
    this.isLoading = true;
    const qb = RequestQueryBuilder.create();
    qb.search(this.buildSearch(query));
    const queryString = qb.query();
    if (this.cancelSource) {
      this.cancelSource.cancel();
    }

    this.cancelSource = axios.CancelToken.source();
    axios
      .get(`${this.endpoint}?${queryString}`, {
        cancelToken: this.cancelSource.token,
      })
      .then((result) => {
        this.options = result.data.data ?? result.data;
      })
      .finally(() => {
        this.isLoading = false;
        this.cancelSource = null;
      });
  }

  throttledFind = debounce(this.find, 400);
}
