
/* eslint-disable no-unused-vars */
import { debounce } from 'lodash';

import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListErrorMessage from '@gid/vue-common/components/crud-list/CrudListErrorMessage.vue';
import CrudDeleteButton from '@gid/vue-common/components/crud-list/CrudDeleteButton.vue';
import ImporterModal, {
  CurrentScreenUrl,
} from '../../components/importer/ImporterModal.vue';
import ReconcilePayment from './ReconcilePayment.vue';
import { AccountingDocumentStatusEnum } from '@gid/models';
import {
  PaymentRecordsModule,
  PaymentRecordsActionsEnum,
} from '../../store/accounting-payments.module';
import {
  CreateQueryParams,
  SConditionAND,
  SFields,
} from '@dataui/crud-request';
import { getModule } from 'vuex-module-decorators';
import * as ibantools from 'ibantools';

interface TargetStatusSelection {
  key: string;
  value: AccountingDocumentStatusEnum;
}

@Component({
  components: {
    DatePicker,
    CrudListPagination,
    CrudListTable,
    CrudListErrorMessage,
    CrudDeleteButton,
    ImporterModal,
    ReconcilePayment,
  },
})
export default class PaymentRecordsView extends Vue {
  @Prop() paymentId?: string;
  @Ref('importModal') readonly importModal;
  @Ref('reconcilePaymentModal') readonly reconcilePaymentModal;

  inProgress: boolean = false;
  currentScreenUrl: CurrentScreenUrl = {
    entityPath: 'payments',
    query: '',
    queryString: '',
    noPagingQuery: '',
    noPagingQueryString: '',
  };

  importUrl(format, currentScreenUrl: CurrentScreenUrl) {
    return '/documents-api/accounting/payments/import/csv';
  }

  created() {
    this.refreshDocumentList();
  }

  // Computed
  get store(): PaymentRecordsModule {
    return getModule(PaymentRecordsModule, this.$store);
  }
  get columns() {
    return [
      {
        key: 'date',
        sortable: true,
        label: 'Date', // this.$t('accounting.document.issue-date'),
      },
      {
        key: 'accountGid',
        sortable: true,
        label: 'Account', //this.$t('accounting.common.document-number'),
      },
      {
        key: 'reference',
        sortable: true,
        label: 'Reference',
      },
      {
        key: 'amount',
        sortable: true,
        label: 'Amount', //this.$t('accounting.common.amount'),
      },
      {
        key: 'nameOther',
        sortable: true,
        label: 'Name', //this.$t('accounting.common.target-name'),
      },
      {
        key: 'accountOther',
        sortable: true,
        label: 'Payer/Payee account', //this.$t('accounting.common.total-amount'),
      },
      {
        key: 'actions',
        label: 'Actions',
        class: 'text-right text-nowrap',
      },
    ];
  }

  @Watch('store.filters', {
    deep: true,
  })
  searchChange(value) {
    this.throttledFind();
  }

  buildSearch() {
    const search: Array<SFields | SConditionAND> = [];
    if (this.paymentId) {
      search.push({
        id: {
          $eq: this.paymentId,
        },
      });
      return { search: { $and: search } };
    }

    if (this.store.filters.dateFrom) {
      search.push({
        date: {
          $gte: this.store.filters.dateFrom,
        },
      });
    }
    if (this.store.filters.dateTo) {
      search.push({
        date: {
          $lte: this.store.filters.dateTo,
        },
      });
    }

    if (this.store.filters.search) {
      const searchIban = ibantools.isValidIBAN(this.store.filters.search)
        ? (ibantools.electronicFormatIBAN(this.store.filters.search) as string)
        : this.store.filters.search;
      search.push({
        $or: [
          {
            nameOther: {
              $contL: this.store.filters.search,
            },
          },
          {
            accountOther: {
              $contL: searchIban,
            },
          },
          {
            referenceText: {
              $contL: this.store.filters.search,
            },
          },
          {
            referenceNumber: {
              $contL: this.store.filters.search,
            },
          },
        ],
      });
    }

    if (this.store.filters.linkedDocuments === 'with') {
      search.push({
        'documents.id': {
          $notnull: this.store.filters.dateTo,
        },
      });
    }

    if (this.store.filters.linkedDocuments === 'without') {
      search.push({
        'documents.id': {
          $isnull: this.store.filters.dateTo,
        },
      });
    }

    return {
      search: {
        $and: search,
      },
    };
  }

  refreshDocumentList() {
    const { search } = this.buildSearch();

    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
    };
    this.store[PaymentRecordsActionsEnum.ITEMS_FETCH]({
      query,
      search,
    });
  }
  throttledFind = debounce(this.refreshDocumentList, 400);

  importFileModal() {
    this.importModal.open();
  }

  onImportError(event) {
    console.error(event);
    this.store.setLastError({
      error: event.data,
    });
  }

  link(payment) {
    this.reconcilePaymentModal.openForPayment(payment.id);
  }
}
