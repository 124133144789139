
import { Vue, Component, Prop } from 'vue-property-decorator';
import PaymentAmount from './PaymentAmount.vue';
@Component({
  components: {
    PaymentAmount,
  },
})
export default class PaymentLabel extends Vue {
  @Prop({ required: true }) payment!: any;
}
