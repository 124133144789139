
import { Vue, Component, Prop } from 'vue-property-decorator';
import PaymentAmount from './PaymentAmount.vue';
@Component({
  components: {
    PaymentAmount,
  },
})
export default class DocumentLabel extends Vue {
  @Prop({ required: true }) document!: any;
  @Prop() newStatus!: string;
  @Prop() documentLink!: boolean;
}
