
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import DocumentLabel from './DocumentLabel.vue';
import { CreateQueryParams, QueryJoin } from '@dataui/crud-request';
import { AccountingDocument } from '@gid/models';

@Component({
  components: {
    DocumentLabel,
    CrudMultiSelect,
  },
})
export default class DocumentSelector extends Vue {
  @Prop({ default: null }) value!: any;
  @Prop() queryParams!: CreateQueryParams;

  get model() {
    return AccountingDocument;
  }
}
